export class OutlookAppointmentStartedFlag {
    StartTime: boolean;
    EndTime: boolean;
    Subject: boolean;
    RequiredRecipients: boolean;
    OptionalRecipients: boolean;
    CustomProperties: boolean;
    Resources: boolean;
    Recurrence: boolean;
    public constructor() {
        this.StartTime = false;
        this.EndTime = false;
        this.Subject = false;
        this.RequiredRecipients = false;
        this.OptionalRecipients = false;
        this.CustomProperties = false;
        this.Resources = false;
        this.Recurrence = false;
    }
    get IsStarted(): boolean {
        return this.StartTime
            && this.EndTime
            && this.RequiredRecipients
            && this.OptionalRecipients
            && this.Subject
            && this.CustomProperties
            && this.Resources
            && this.Recurrence;
    }
}