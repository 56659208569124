import { IOutlookAppointmentExtractor, IOutlookOrganizerRepository, OutlookRecipientDto, TokenIOutlookOrganizerRepository } from "booking-app-op";
import { AppointmentModel, OutlookRecipient } from "../models";
import { container } from "tsyringe";

export class OutlookAppointmentExtractor implements IOutlookAppointmentExtractor {
    private _apptItem: Office.AppointmentCompose;
    private _callback: (appInfo?: AppointmentModel) => void;
    private _olOrganizerRepository: IOutlookOrganizerRepository;
    constructor(apptItem: Office.AppointmentCompose, callback: (appInfo?: AppointmentModel) => void) {
        this._apptItem = apptItem;
        this._callback = callback;
        this._olOrganizerRepository = container.resolve(TokenIOutlookOrganizerRepository);
    }
    Extract(): void {
        if (this._apptItem) {
            let apptInfo: AppointmentModel = {
                StartTime: new Date(),
                EndTime: new Date(),
                Recipients: [],
                EnhancedLocations: []
            }
            let options: Office.AsyncContextOptions = {
                asyncContext: apptInfo
            }
            this._apptItem.requiredAttendees.getAsync(options, this._getRequiredRecipientCallback.bind(this));
        }
    }
    private _getRequiredRecipientCallback(asyncResult: Office.AsyncResult<Array<Office.EmailAddressDetails>>) {
        try {
            let requiredRecipients: Array<Office.EmailAddressDetails> = asyncResult.value;
            let requiredOlRecipients: OutlookRecipientDto[] = requiredRecipients ? requiredRecipients.map(rs => {
                let recipient: OutlookRecipientDto = {
                    Email: rs.emailAddress,
                    Name: rs.displayName
                };
                return recipient;
            }) : [];

            var apptInfo = asyncResult.asyncContext as AppointmentModel;
            requiredOlRecipients.forEach(r => {
                apptInfo.Recipients.push(new OutlookRecipient(r.Email, r.Name, r.RecipientType, r.ResourceType));
            });

            let options: Office.AsyncContextOptions = {
                asyncContext: apptInfo
            }
            this._apptItem.optionalAttendees.getAsync(options, this.getOptionalRecipientCallback.bind(this));
        } catch (ex) {
            //console.log(ex);
            this._callback();
        } finally {

        }
    }
    private getOptionalRecipientCallback(asyncResult: Office.AsyncResult<Array<Office.EmailAddressDetails>>) {
        try {
            let optionalRecipients: Array<Office.EmailAddressDetails> = asyncResult.value;
            let optionalOlRecipients: OutlookRecipientDto[] = optionalRecipients ? optionalRecipients.map(rs => {
                let recipient: OutlookRecipientDto = {
                    Email: rs.emailAddress,
                    Name: rs.displayName
                };
                return recipient;
            }) : [];

            var apptInfo = asyncResult.asyncContext as AppointmentModel;
            optionalOlRecipients.forEach(r => {
                apptInfo.Recipients.push(new OutlookRecipient(r.Email, r.Name, r.RecipientType, r.ResourceType));
            });

            let options: Office.AsyncContextOptions = {
                asyncContext: apptInfo
            }
            if (Office.context.requirements.isSetSupported('Mailbox', 1.8)) {
                this._apptItem.enhancedLocation.getAsync(options, this.getEnhancedLocationsCallback.bind(this));
            }
        } catch (ex) {
            //console.log(ex);
            this._callback();
        } finally {

        }
    }
    private getEnhancedLocationsCallback(asyncResult: Office.AsyncResult<Array<Office.LocationDetails>>) {
        try {
            let locations: Array<Office.LocationDetails> = asyncResult.value;
            let olLocations: OutlookRecipientDto[] = locations ? locations.map(rs => {
                let recipient: OutlookRecipientDto = {
                    Email: rs.emailAddress,
                    Name: rs.displayName
                };
                return recipient;
            }) : [];

            var apptInfo = asyncResult.asyncContext as AppointmentModel;
            olLocations.forEach(r => {
                apptInfo.Recipients.push(new OutlookRecipient(r.Email, r.Name, r.RecipientType, r.ResourceType));
                apptInfo.EnhancedLocations.push(new OutlookRecipient(r.Email, r.Name, r.RecipientType, r.ResourceType));
            });

            this._callback(apptInfo);
            //let options: Office.AsyncContextOptions = {
            //    asyncContext: apptInfo
            //}
            //this._apptItem.start.getAsync(options, this.getStartTimeCallback.bind(this));
        } catch (ex) {
            //console.log(ex);
            this._callback();
        } finally {

        }
    }

    private getStartTimeCallback(asyncResult: Office.AsyncResult<Date>) {
        try {
            var apptInfo = asyncResult.asyncContext as AppointmentModel;
            apptInfo.StartTime = asyncResult.value;
            let options: Office.AsyncContextOptions = {
                asyncContext: apptInfo
            }
            this._apptItem.end.getAsync(options, this.getEndTimeCallback.bind(this));
        } catch (ex) {
            //console.log(ex);
            this._callback();
        } finally {

        }
    }

    private getEndTimeCallback(asyncResult: Office.AsyncResult<Date>) {
        try {
            var apptInfo = asyncResult.asyncContext as AppointmentModel;
            apptInfo.EndTime = asyncResult.value;
            let options: Office.AsyncContextOptions = {
                asyncContext: apptInfo
            }
            this._apptItem.recurrence.getAsync(options, this.getRecurrenceCallback.bind(this));
        } catch (ex) {
            //console.log(ex);
            this._callback();
        } finally {

        }
    }

    private getRecurrenceCallback(asyncResult: Office.AsyncResult<Office.Recurrence>) {
        try {
            var apptInfo = asyncResult.asyncContext as AppointmentModel;
            if (asyncResult.status === Office.AsyncResultStatus.Succeeded && asyncResult.value) {
                apptInfo.Recurrence = asyncResult.value;
            }
            apptInfo.Organizer = this._olOrganizerRepository.GetOrganizer();
            this._callback(apptInfo);
        } catch (ex) {
            //console.log(ex);
            this._callback();
        } finally {

        }
    }
}