import { DependencyContainer, Lifecycle } from "tsyringe";

import {
  IAppointmentManager,
  IAuthenticationService,
  IDialogService,
  IVrService,
  TokenIVrService,
  IOutlookOrganizerRepository,
  IOutlookSettingsRepository,
  IPlatformService,
  RegisterRFServices,
  TokenIAppointmentManager,
  TokenIAuthenticationService,
  TokenIDialogService,
  TokenIOutlookOrganizerRepository,
  TokenIOutlookSettingsRepository,
  TokenIPlatformService,
} from "booking-app-op";
import { AuthenticationService } from "../authentication";
import { OutlookAppointmentManager, PlatformService } from "../infrastructures/implementations";
import { OutlookOrganizerRepository, OutlookSettingsRepository } from "../repositories";
import { VrService } from "../infrastructures/implementations/VrService";
import { DialogService } from "../infrastructures/implementations/DialogService";
import { INestedAppAuthenticationService, TokenINestedAppAuthenticationService } from "../authentication/INestedAppAuthenticationService";
import { NestedAppAuthenticationService } from "../authentication/NestedAppAuthenticationService";

export const RegisterOfficeServices = (container: DependencyContainer) => {
  //Service BookingApp
  RegisterRFServices(container);

  container.register<IAuthenticationService>(
    TokenIAuthenticationService,
    { useClass: AuthenticationService },
    { lifecycle: Lifecycle.Singleton }
  );
  container.register<IAppointmentManager>(
    TokenIAppointmentManager,
    { useClass: OutlookAppointmentManager },
    { lifecycle: Lifecycle.Singleton }
  );
  container.register<IOutlookOrganizerRepository>(TokenIOutlookOrganizerRepository, {
    useClass: OutlookOrganizerRepository,
  });
  container.register<IOutlookSettingsRepository>(TokenIOutlookSettingsRepository, {
    useClass: OutlookSettingsRepository,
  });
  container.register<IPlatformService>(TokenIPlatformService, {
    useClass: PlatformService,
  });
  container.register<IVrService>(TokenIVrService, { useClass: VrService }, { lifecycle: Lifecycle.Singleton });
  container.register<IDialogService>(
    TokenIDialogService,
    { useClass: DialogService },
    { lifecycle: Lifecycle.Singleton }
  );
  container.register<INestedAppAuthenticationService>(TokenINestedAppAuthenticationService, { useClass: NestedAppAuthenticationService }, { lifecycle: Lifecycle.Singleton });

  // container.register<IResourceManager>(
  //   TokenIResourceManager,
  //   { useClass: ResourceManager },
  //   { lifecycle: Lifecycle.Singleton }
  // );
};
