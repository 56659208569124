// flag to lock the appointment object when process update.
export class OutlookAppointmentLocker {
    DateTime: boolean;
    Subject: boolean;
    RequiredRecipients: boolean;
    OptionalRecipients: boolean;
    constructor() {
        this.DateTime = false;
        this.Subject = false;
        this.RequiredRecipients = false;
        this.OptionalRecipients = false;
    }
}