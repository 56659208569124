// Importing necessary types and interfaces from "booking-app-op" module
import { DialogOptionsDto, IDialogModel, IDialogService, DialogEventType } from "booking-app-op";

// Implementation of IDialogService interface
export class DialogService implements IDialogService {
  // Method to display a dialog box

  /**
   * Displays a dialog box with the specified URL and options.
   * @param url The URL of the page to display in the dialog box.
   * @param options The options for the dialog box.
   * @param callback A callback function to be called when the dialog box is displayed.
   */
  ShowDialog(url: string, options?: DialogOptionsDto, callback?: (dialog: IDialogModel) => void): void {
    // Setting up dialog options
    let dialogOptions: Office.DialogOptions = {
      displayInIframe: true,
      width: options?.Width,
      height: options?.Height,
    };

    // Callback function to handle the result of displayDialogAsync method
    const officeCallback = (asyncResult: Office.AsyncResult<Office.Dialog>) => {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        // If the dialog box is successfully displayed, create a dialog model object
        const dialogObject = asyncResult.value;
        const dialogModel: IDialogModel = {
          // Method to add an event handler to the dialog box
          addEventHandler: (
            eventType: DialogEventType,
            handler: (args: { message: string; origin: string | undefined } | { error: number }) => void
          ) => {
            const officeEventType =
              eventType === DialogEventType.DialogEventReceived
                ? Office.EventType.DialogEventReceived
                : Office.EventType.DialogMessageReceived;
            dialogObject.addEventHandler(officeEventType, handler);
          },
          // Method to close the dialog box
          close: () => {
            dialogObject.close();
          },
          // Method to send a message to the child window of the dialog box
          messageChild: (message: string, messageOptions?: { targetOrigin: string }) => {
            if (!messageOptions) {
              dialogObject.messageChild(message);
            } else dialogObject.messageChild(message, messageOptions);
          },
        };
        // Call the callback function with the dialog model object
        callback?.(dialogModel);
      } else {
        console.error("Failed to open dialog:", asyncResult.error); // Log error
      }
    };
    // Display the dialog box using displayDialogAsync method
    Office.context.ui.displayDialogAsync(url, dialogOptions, officeCallback);
  }

  // Method to send a message to the parent window of the dialog box
  MessageParent(messageObject: string): void {
    Office.context.ui.messageParent(messageObject);
  }

  SaveSessionDataDialog(sessionData: string): void {
    const item = Office.context.mailbox.item;
    const asyncContext = sessionData || "";
    if (item) {
      item.loadCustomPropertiesAsync(this.customPropsCallback, asyncContext);
      item.sessionData.getAsync("TEMP", (asyncResult) => {
        this.sessionCallback(asyncResult, sessionData);
      });
    }
  }

  private sessionCallback = (asyncResult: Office.AsyncResult<string>, sessionData: string): void => {
    let currentValue = asyncResult.value || "";

    // Checking and concatenating values
    if (currentValue.indexOf(",") > -1) {
      const existingValues = new Set(currentValue.split(","));
      const newValues = sessionData.split(",");
      newValues.forEach((value) => existingValues.add(value)); // Avoid duplicates
      currentValue = Array.from(existingValues).join(",");
    } else if (currentValue && sessionData) {
      currentValue += "," + sessionData;
    } else {
      currentValue = sessionData;
    }

    const item = Office.context.mailbox.item;
    if (item) {
      item.sessionData.setAsync("TEMP", currentValue);
    }
  };

  customPropsCallback = (asyncResult: Office.AsyncResult<Office.CustomProperties>): void => {
    if (asyncResult.status === Office.AsyncResultStatus.Failed) {
      // Handle error
      return;
    }

    const customProps = asyncResult.value;
    let rcTempId = customProps.get("rcTempId") || "";
    const newValue = asyncResult.asyncContext as string;
    // Checking and concatenating values
    if (rcTempId.indexOf(",") > -1) {
      const existingValues = new Set(rcTempId.split(","));
      const newValues = newValue.split(",");
      newValues.forEach((value) => existingValues.add(value)); // Avoid duplicates
      rcTempId = Array.from(existingValues).join(",");
    } else if (rcTempId && newValue) {
      rcTempId += "," + newValue;
    } else {
      rcTempId = newValue;
    }

    customProps.set("rcTempId", rcTempId);
    customProps.saveAsync(this.saveCallback);
  };

  saveCallback = (asyncResult: Office.AsyncResult<void>): void => {
    if (asyncResult.status === Office.AsyncResultStatus.Failed) {
      console.error(asyncResult.error.message);
    } else {
      const item = Office.context.mailbox.item;
      if (item) {
        console.log(
          item.loadCustomPropertiesAsync((a) => {
            console.log(a.value.getAll());
          })
        );
      }
      // Proceed with the appropriate action for your add-in.
    }
  };
}
