
import {  PublicClientNext, type IPublicClientApplication } from "@azure/msal-browser";
import { INestedAppAuthenticationService } from "./INestedAppAuthenticationService";

export class NestedAppAuthenticationService implements INestedAppAuthenticationService {
  private loginHint: string = "";
  private pca: IPublicClientApplication | undefined = undefined;

  async initialize(loginHint: string, clientId: string, _tenantId: string, callBackUrl :string) {
    const msalConfig = {
      auth: {
        clientId: clientId,
        // authority: `https://login.microsoftonline.com/${tenantId}`, //181be701-8740-4fbd-b90f-fed56225b7c8,
        authority: `https://login.microsoftonline.com/organizations`, //181be701-8740-4fbd-b90f-fed56225b7c8,
        supportsNestedAppAuth: true,
        redirectUri: callBackUrl, // Must be registered as "spa" type
      },
      cache: {
        cacheLocation: "localStorage", // Needed to avoid "User login is required" error.
        storeAuthStateInCookie: true, // Recommended to avoid certain IE/Edge issues.
      },
    };

    this.loginHint = loginHint;
    this.pca = await PublicClientNext.createPublicClientApplication(msalConfig);
  }

  async ssoGetToken(scopes: string[]) {
    const userAccount = await this.ssoGetUserIdentity(scopes);
    return userAccount.accessToken;
  }

  async ssoGetUserIdentity(scopes: string[]) {
    if (this.pca === undefined) {
      throw new Error("😖 AccountManager is not initialized!");
    }

    // Specify minimum scopes needed for the access token.
    const tokenRequest = {
      scopes: scopes,
      loginHint: this.loginHint,
    };

    try {
      console.log("▶️ Trying to acquire token silently...");

      //acquireTokenSilent requires an active account. Check if one exists, otherwise use ssoSilent.
      const account = this.pca.getActiveAccount();
      const userAccount = account ? await this.pca.acquireTokenSilent(tokenRequest) : await this.pca.ssoSilent(tokenRequest);

      console.log("✅ Acquired token silently. ");
      return userAccount;
    } catch (error) {
      console.log(`❌ Unable to acquire token silently: ${error}`);
    }

    // Acquire token silent failure. Send an interactive request via popup.
    try {
      console.log("▶️ Trying to acquire token interactively...");
      const userAccount = await this.pca.acquireTokenPopup(tokenRequest);
      console.log("☑️ Acquired token interactively.");
      return userAccount;
    } catch (popupError) {
      // Acquire token interactive failure.
      console.log(`❌ Unable to acquire token interactively: ${popupError}`);
      throw new Error(`Unable to acquire access token: ${popupError}`);
    }
  }
}
