import { OutlookOrganizerDto } from "./dto/OutlookOrganizerDto";
import { OutlookRecipientDto } from "./dto/OutlookRecipientDto";

export class OutlookOrganizerRepository {
  GetOrganizer(): OutlookOrganizerDto | undefined {
    let organizer: OutlookOrganizerDto | undefined;
    try {
      if (Office.context && Office.context.mailbox) {
        let olUserProfile = Office.context.mailbox.userProfile;
        if (olUserProfile) {
          organizer = {
            Email: olUserProfile.emailAddress,
            DisplayName: olUserProfile.displayName,
            TimeZoneName: olUserProfile.timeZone,
          };
        }
      }
    } catch (ex) {
      //console.log(ex);
    }
    return organizer;
  }

  async GetRealOrganizerOfAppointment(): Promise<OutlookRecipientDto | undefined> {
    let item = Office?.context?.mailbox?.item;
    let organizer: OutlookRecipientDto | undefined;

    if (typeof item?.getSharedPropertiesAsync != "function") return organizer;

    if (item && item.itemType === Office.MailboxEnums.ItemType.Appointment) {
      let appCompose = item as Office.AppointmentCompose;

      if (appCompose) {
        // Sử dụng Promise để đồng bộ hóa kết quả
        organizer = await new Promise<OutlookRecipientDto | undefined>((resolve, reject) => {
          appCompose.organizer.getAsync((result) => {
            if (result.status === Office.AsyncResultStatus.Failed) {
              return reject(new Error(result.error.message));
            }

            if (result.value) {
              resolve({
                Email: result.value.emailAddress,
                Name: result.value.displayName,
              });
            } else {
              resolve(undefined);
            }
          });
        });
      }
    }

    return organizer;
  }
}
