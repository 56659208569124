import { WebAddinPlatforms ,ConvertUtils} from "booking-app-op";

export class PlatformHelper {
    public static GetCurrentPlatform(): WebAddinPlatforms {
        if (Office.context.diagnostics) {
            let currentPlatforms = Office.context.diagnostics;
            if (currentPlatforms.host === Office.HostType.Outlook) {
                if (currentPlatforms.platform === Office.PlatformType.Mac) {
                    return WebAddinPlatforms.OutlookForMac;
                }
                else {
                    if (currentPlatforms.platform === Office.PlatformType.PC) {
                        let version = currentPlatforms.version;
                        let versionArr = version.split('.');
                        if (ConvertUtils.ToInt(versionArr[0]) === 16) {
                            return WebAddinPlatforms.Outlook2016;
                        }
                        return WebAddinPlatforms.DesktopClient;
                    }
                    if (currentPlatforms.platform === Office.PlatformType.OfficeOnline) {
                        return WebAddinPlatforms.OWA;
                    }
                }
            }
        }
        else {
            let currentPlatforms = Office.context.mailbox.diagnostics;
            if (currentPlatforms) {
                let version = currentPlatforms.hostVersion;
                let versionArr = version.split('.');
                if (ConvertUtils.ToInt(versionArr[0]) === 16) {
                    return WebAddinPlatforms.Outlook2016;
                }
            }
        }
        return WebAddinPlatforms?.undefined;
    }

    public static IsOnPremSystem() {
        return Office.context.mailbox.userProfile.accountType == "enterprise";
    }

    public static GetSpecificVersion(): number {
        let _isStopCheck = false;
        let baseVersion = 9;
        while (!_isStopCheck) {
            if (Office.context.requirements.isSetSupported('Mailbox', '1.' + baseVersion.toString()) || baseVersion == 0) {
                _isStopCheck = true;
            }
            baseVersion--;
        }
        return baseVersion;
    }

    public static GetRunningPlatform(): Office.PlatformType {
        if (Office.context.diagnostics) {
            return Office.context.diagnostics.platform;
        }
        else {
            return Office.PlatformType.PC;
        }
    }

    public static GetPlatformString(): string {
        if (Office.context.diagnostics) {
            let currentPlatforms = Office.context.diagnostics;
            if (currentPlatforms.host === Office.HostType.Outlook) {
                if (currentPlatforms.platform === Office.PlatformType.Mac) {
                    return "OutlookForMac";
                }
                else {
                    if (currentPlatforms.platform === Office.PlatformType.PC) {
                        return "OutlookBuild " + currentPlatforms.version;
                    }
                    if (currentPlatforms.platform === Office.PlatformType.OfficeOnline) {
                        return "OfficeOnline";
                    }
                }
            }
        }
        else {
            let currentPlatforms = Office.context.mailbox.diagnostics;
            if (currentPlatforms) {
                return "OutlookBuild " + currentPlatforms.hostVersion;
            }
        }
        return "Unknown";
    }
}