// Importing necessary types and interfaces from "booking-app-op" module
import { IVrService } from "booking-app-op";

// Implementation of IDialogService interface
export class VrService implements IVrService {
  // Method to display a dialog box

  SaveSessionDataDialog(sessionData: string): void {
    if (sessionData && sessionData != "") {
      const item = Office.context.mailbox.item;
      const asyncContext = sessionData || "";
      if (item) {
        item.loadCustomPropertiesAsync(this.customPropsCallback, asyncContext);
        item.sessionData.getAsync("TEMP", (asyncResult) => {
          this.sessionCallback(asyncResult, sessionData);
        });
      }
    }
  }

  private sessionCallback = (asyncResult: Office.AsyncResult<string>, sessionData: string): void => {
    let currentValue = asyncResult.value || "";

    // Checking and concatenating values
    if (currentValue.indexOf(",") > -1) {
      const existingValues = new Set(currentValue.split(","));
      const newValues = sessionData.split(",");
      newValues.forEach((value) => existingValues.add(value)); // Avoid duplicates
      currentValue = Array.from(existingValues).join(",");
    } else if (currentValue && sessionData) {
      currentValue += "," + sessionData;
    } else {
      currentValue = sessionData;
    }

    const item = Office.context.mailbox.item;
    if (item) {
      item.sessionData.setAsync("TEMP", currentValue);
    }
  };

  customPropsCallback = (asyncResult: Office.AsyncResult<Office.CustomProperties>): void => {
    if (asyncResult.status === Office.AsyncResultStatus.Failed) {
      // Handle error
      return;
    }

    const customProps = asyncResult.value;
    let rcTempId = customProps.get("rcTempId") || "";
    const newValue = asyncResult.asyncContext as string;
    // Checking and concatenating values
    if (rcTempId.indexOf(",") > -1) {
      const existingValues = new Set(rcTempId.split(","));
      const newValues = newValue.split(",");
      newValues.forEach((value) => existingValues.add(value)); // Avoid duplicates
      rcTempId = Array.from(existingValues).join(",");
    } else if (rcTempId && newValue) {
      rcTempId += "," + newValue;
    } else {
      rcTempId = newValue;
    }

    customProps.set("rcTempId", rcTempId);
    customProps.saveAsync(this.saveCallback);
  };

  saveCallback = (asyncResult: Office.AsyncResult<void>): void => {
    if (asyncResult.status === Office.AsyncResultStatus.Failed) {
      console.error(asyncResult.error.message);
    } else {
      const item = Office.context.mailbox.item;
      if (item) {
        console.log(
          item.loadCustomPropertiesAsync((a) => {
            console.log(a.value.getAll());
          })
        );
      }
      // Proceed with the appropriate action for your add-in.
    }
  };
}
