import {
  IAuthenticationService,
  IOrganizationService,
  TokenIOrganizationService,
  baContainer,
  IConfiguration,
} from "booking-app-op";
import axios, { AxiosResponse } from "axios";
// import { processDialogEvent } from "./office-apis-helpers";
import {
  INestedAppAuthenticationService,
  TokenINestedAppAuthenticationService,
} from "./INestedAppAuthenticationService";
import { PERMISSION } from "../types/permission";

interface DataModel {
  organizerEmail?: string;
  accessToken?: string;
  refreshToken?: string;
  urlRefreshToken?: string;
  expiredTime?: string;
}

export class AuthenticationService implements IAuthenticationService {
  private _baseURL = window.RESOURCE_CENTRAL_ENDPOINT;
  private _baseAPI_URL = window.API_ENDPOINT;

  private _nestedAppAuthenticationService: INestedAppAuthenticationService;
  private _token: string | undefined = undefined;

  constructor() {
    this._nestedAppAuthenticationService = baContainer.resolve(TokenINestedAppAuthenticationService);
  }
  async GetCallbackToken() {
    try {
      const accessToken = await this._nestedAppAuthenticationService.ssoGetToken(PERMISSION);
      return accessToken;
    } catch (error) {
      return "";
    }
  }

  async GetTokenFromAAD(): Promise<string> {
    let userTokenEncoded = await Office.auth.getAccessToken({ allowSignInPrompt: true, allowConsentPrompt: true });
    return userTokenEncoded;
  }

  async GetAccessTokenFromAAD(_configuration: IConfiguration): Promise<boolean> {
    // let organizer = Office.context.mailbox.userProfile;
    try {
      const getToken = await this.GetCallbackToken();
      if (getToken) {
        return true;
      } else {
        return false;
      }
      // let userTokenEncoded = await Office.auth.getAccessToken({ allowSignInPrompt: true, allowConsentPrompt: true });

      // if (userTokenEncoded) {
      //   //send this token to back-end
      //   const url = this._baseAPI_URL + "/config-authen/aquire-token";
      //   const headers = {
      //     accept: "text/plain",
      //     "Content-Type": "application/json-patch+json",
      //   };

      //   const payload = JSON.stringify(userTokenEncoded);
      //   const response = await axios.post(url, payload, { headers }).then((response) => {
      //     return response.data;
      //   });
      //   //Lưu token sang RC
      //   // await this.saveTokenToRC(
      //   //   organizer.emailAddress,
      //   //   response?.AccessToken,
      //   //   response?.IdToken,
      //   //   response?.ExpiresOn,
      //   //   configuration
      //   // );

      //   Office.context.mailbox.item?.sessionData.setAsync("token", response?.AccessToken, function (asyncResult: any) {
      //     if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
      //       console.log("sessionData.setAsync succeeded");
      //     } else {
      //       console.log("Failed to set sessionData. Error: " + JSON.stringify(asyncResult.error));
      //     }
      //   });

      //   // let data = {
      //   //   token: userTokenEncoded,
      //   //   organizerName: organizer.displayName,
      //   //   organizerEmail: organizer.emailAddress,
      //   // };
      //   // if (userTokenEncoded && organizer) {
      //   //   let request = JSON.stringify(data);
      //   //   const config = {
      //   //     method: "post",
      //   //     url: this._baseURL + "/Admin/OfficeAddInAuth/Authorize",
      //   //     headers: {
      //   //       "Content-Type": "application/json",
      //   //     },
      //   //     data: request,
      //   //   };

      //   //   await axios.post(this._baseURL + "/Admin/OfficeAddInAuth/Authorize", request, config);
      //   // }
      //   return true;
      // } else {
      //   return false;
      //   // alert("userTokenEncoded error");
      //   // throw new Error("userTokenEncoded error");
      // }
    } catch (exception: any) {
      //console.log(["EXCEPTION 1: " + JSON.stringify(exception)]);
      // const storedObjStr = sessionStorage.getItem("AADToken") || "";
      // const storedObj = storedObjStr ? JSON.parse(storedObjStr) : null;

      // if (storedObj) {
      //   sessionStorage.removeItem("AADToken");
      //   //Save token to RC
      //   // await this.saveTokenToRC(
      //   //   organizer.emailAddress,
      //   //   storedObj?.accessToken,
      //   //   storedObj?.idToken,
      //   //   storedObj?.expiresOn,
      //   //   configuration
      //   // );

      //   // let _data = {
      //   //   token: storedObj?.accessToken,
      //   //   organizerName: organizer.displayName,
      //   //   organizerEmail: organizer.emailAddress,
      //   // };
      //   // let request = JSON.stringify(_data);
      //   // let config = {
      //   //   method: "post",
      //   //   url: this._baseURL + "/Admin/OfficeAddInAuth/Authorize",
      //   //   headers: {
      //   //     "Content-Type": "application/json",
      //   //   },
      //   //   data: request,
      //   // };

      //   // await axios.post(this._baseURL + "/Admin/OfficeAddInAuth/Authorize", request, config);

      //   return true;
      // }

      // // The only exceptions caught here are exceptions in your code in the try block
      // // and errors returned from the call of `getAccessToken` above.
      // if (exception.code && (storedObj === null || storedObj === undefined)) {
      //   try {
      //     const result = await this.getTokenSSO(organizer, configuration);
      //     return result;
      //   } catch (err: any) {
      //     let intervalId = setInterval(function () {
      //       let divElement = document.getElementById("msg-error-addin");
      //       if (divElement) {
      //         clearInterval(intervalId); // Dừng vòng lặp khi divElement tồn tại
      //         divElement.textContent = processDialogEvent(err);
      //       }
      //     }, 1000); // Kiểm tra mỗi giây (có thể thay đổi thời gian kiểm tra tùy ý)

      //     // window.location.reload();
      //     // alert("Error: User closed the dialog instead of waiting for it to close" + err?.error);
      //   }
      // } else {
      //   console.log(["EXCEPTION 2: " + JSON.stringify(exception)]);
      // }

      return false;
    }
    // return true;
  }

  async getTokenSSO(organizer: Office.UserProfile, _configuration: any): Promise<boolean> {
    const userAccount = await this._nestedAppAuthenticationService.ssoGetUserIdentity(PERMISSION);

    if (userAccount) {
      const object = {
        accessToken: userAccount.accessToken,
        idToken: userAccount.idToken,
        expiresOn: userAccount.expiresOn,
      };
      //Save token to sessionStorage
      sessionStorage.setItem("AADToken", JSON.stringify(object));

      // let expiresOn = userAccount.expiresOn; // Assign a Date value or null to the variable

      // let expiresOnString = expiresOn !== null ? expiresOn.toString() : "";

      //Save token to RC
      // await this.saveTokenToRC(
      //   organizer.emailAddress,
      //   userAccount.accessToken,
      //   userAccount.idToken,
      //   expiresOnString,
      //   configuration
      // );

      // let data = {
      //   token: userAccount.accessToken,
      //   organizerName: organizer.displayName,
      //   organizerEmail: organizer.emailAddress,
      // };
      if (organizer) {
        // let request = JSON.stringify(data);
        // let config = {
        //   method: "post",
        //   url: this._baseURL + "/Admin/OfficeAddInAuth/Authorize",
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        //   data: request,
        // };

        // await axios.post(this._baseURL + "/Admin/OfficeAddInAuth/Authorize", request, config);

        return true;
      }
    }
    return false;
  }

  handleClientSideErrors(code: number): void {
    switch (code) {
      case 13001:
        // No one is signed into Office. If the add-in cannot be effectively used when no one
        // is logged into Office, then the first call of getAccessToken should pass the
        // `allowSignInPrompt: true` option.
        //console.log(["No one is signed into Office."]);
        break;
      case 13002:
        // The user aborted the consent prompt. If the add-in cannot be effectively used when consent
        // has not been granted, then the first call of getAccessToken should pass the `allowConsentPrompt: true` option.
        //console.log(["You can use many of the add-ins functions even though you have not granted consent. If you want to grant consent, press the Get OneDrive File Names button again."]);
        break;
      case 13006:
        // Only seen in Office on the web.
        //console.log(["Office on the web is experiencing a problem. Please sign out of Office, close the browser, and then start again."]);
        break;
      case 13008:
        // Only seen in Office on the web.
        //console.log(["Office is still working on the last operation. When it completes, try this operation again."]);
        break;
      case 13010:
        // Only seen in Office on the web.
        //console.log(["Follow the instructions to change your browser's zone configuration."]);
        break;
      default:
        //console.log("Dialog fallback will show...");
        // For all other errors, including 13000, 13003, 13005, 13007, 13012, and 50001, fall back
        // to non-SSO sign-in.
        // this.dialogFallback();
        break;
    }
  }

  dialogFallback(): void {
    let organizer = Office.context.mailbox.userProfile;
    // let url =
    //   this._baseURL +
    //   "/Admin/OfficeAddInAuth/Login?host=" +
    //   location.hostname +
    //   "&port=" +
    //   location.port +
    //   "&protocol=" +
    //   location.protocol +
    //   "&organizerName=" +
    //   organizer.displayName +
    //   "&organizerEmail=" +
    //   organizer.emailAddress;

    // let fullUrl = location.protocol + "//" + location.hostname + (location.port ? ":" + location.port : "") + url;

    // height and width are percentages of the size of the parent Office application, e.g., PowerPoint, Excel, Word, etc.
    let url =
      "/ResourceCentral/Admin/OfficeAddInAuth/Login?host=" +
      location.hostname +
      "&port=" +
      location.port +
      "&protocol=" +
      location.protocol +
      "&organizerName=" +
      organizer.displayName +
      "&organizerEmail=" +
      organizer.emailAddress;
    let fullUrl = location.protocol + "//" + location.hostname + (location.port ? ":" + location.port : "") + url;
    Office.context.ui.displayDialogAsync(fullUrl, { height: 60, width: 30 }, this._processMessage);
  }

  private _processMessage(asyncResult: Office.AsyncResult<Office.Dialog>) {
    let dialog: Office.Dialog = asyncResult.value;
    //console.log("Dialog has initialized. Wiring up events");
    if (dialog != null) {
      dialog.addEventHandler(Office.EventType.DialogEventReceived, (args: any) => {
        if (args.error) {
          //console.log("Error received in processMessage: " + args.error);
        }
      });
      dialog.addEventHandler(Office.EventType.DialogMessageReceived, (args: any) => {
        if (args.message) {
          //console.log("Message received in processMessage: ");
          let message = JSON.parse(args.message);

          if (message.status === "success") {
            sessionStorage.setItem("AADToken", message.accessToken);
            dialog.close();
            window.location.reload();
          } else {
            // Something went wrong with authentication or the authorization of the web application.
            //dialog.close();
          }
        }
      });
    }
  }

  async GetUserIdentityToken(): Promise<string> {
    if(!this._token) {
      try {
        const accessToken = await this._nestedAppAuthenticationService.ssoGetToken(PERMISSION);
        if (accessToken) {
          sessionStorage.setItem("token", accessToken);
          this._token = accessToken;
        }
      } catch (error) {
        console.log("🚀 ~ AuthenticationService ~ GetUserIdentityToken ~ error:", error);
        this._token = "";
      }
    }
    return this._token;

    // return new Promise<string>((resolve: any) => {
    //   Office.context.mailbox.getUserIdentityTokenAsync(function (res) {
    //     if (res.status === Office.AsyncResultStatus.Succeeded) {
    //       resolve(res.value);
    //       sessionStorage.setItem("token", res.value);
    //     }
    //   });
    // });
  }

  //Hàm kết hợp lấy config auth và gửi token sang RC
  async saveTokenToRC(
    _organizerEmail: string,
    accessToken: string,
    idToken: string,
    expiresOn: string,
    configuration: any
  ): Promise<void> {
    //Send token to RC

    const _olOrganizerService: IOrganizationService = baContainer.resolve(TokenIOrganizationService);
    const organizer = await _olOrganizerService.GetCurrentOrganizer();

    const payloadToRc: DataModel = {
      organizerEmail: organizer?.Email || "",
      accessToken: accessToken,
      refreshToken: idToken,
      expiredTime: expiresOn,
      urlRefreshToken: `https://login.microsoftonline.com/${configuration?.tenantId}/oauth2/v2.0/token`,
    };
    await this.updateOrganizerPreferences(payloadToRc);
  }

  async updateOrganizerPreferences(payload: DataModel): Promise<void> {
    // const url = "https://ba2.eastasia.cloudapp.azure.com/ResourceCentralOrderService/api/v1/OfficeApp/updateOrganizerPreferences";
    const url = this._baseAPI_URL + "/gateway/order-api/v1/OfficeApp/updateOrganizerPreferences";
    // console.log("updateOrganizerPreferences" , payload);
    const headers = {
      accept: "text/plain",
      "Content-Type": "application/json-patch+json",
    };

    await axios
      .post(url, payload, { headers })
      .then((_response) => {
        console.log(_response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async fetchConfigAuth() {
    const response: AxiosResponse = await axios.get(`${window.API_ENDPOINT}/config-authen/get-config-auth`);
    return response?.data;
  }
}
