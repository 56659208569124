import { ExchangeResourceTypeEnum, OutlookRecipientTypeEnum } from "booking-app-op";

export class OutlookRecipient {
    private _email: string;
    private _resourceType: ExchangeResourceTypeEnum;
    public RecipientType: OutlookRecipientTypeEnum;
    public set Email(email: string) {
        this._email = email;
    }
    public get Email(): string {
        return this._email;
    }
    public set ResourceType(resourceType: ExchangeResourceTypeEnum) {
        this._resourceType = resourceType;
    }
    public get ResourceType(): ExchangeResourceTypeEnum {
        return this._resourceType;
    }
    public Name: string;
    public constructor(
        email: string,
        name: string,
        recipientType?: OutlookRecipientTypeEnum,
        resourceType?: ExchangeResourceTypeEnum
    ) {
        this._email = email.toLowerCase();
        if (name != null && name != "") {
            this.Name = name;
        } else {
            this.Name = this._email;
        }
        if (recipientType) {
            this.RecipientType = recipientType;
        } else {
            this.RecipientType = OutlookRecipientTypeEnum.Required;
        }
        if (resourceType) {
            this._resourceType = resourceType;
        } else {
            this._resourceType = ExchangeResourceTypeEnum.Room;
        }
    }
}
