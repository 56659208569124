export class OutlookAppointmentWatchingFlag {
    EnableWatchTime: boolean;
    EnableWatchRecipients: boolean;
    EnableWatchSubject: boolean;
    EnableWatchEnhancedLocations: boolean;
    constructor() {
        this.EnableWatchTime = true;
        this.EnableWatchRecipients = true;
        // in requirement set 1.7, subject changed event is not support, so we need watch subject manually.
        this.EnableWatchSubject = true;    
        this.EnableWatchEnhancedLocations = false;    
    }
    get EnableWatching(): boolean {
        return this.EnableWatchTime
            || this.EnableWatchRecipients
            || this.EnableWatchSubject;            
    }
}